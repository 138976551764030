import React, { FC } from "react";
import "./styles.scss";
import { useAppSelector } from "../../../store/hooks";
import { selectLoaderStatus } from "../../../store/features/common/loader";

const Loader: FC = () => {
  const isLoaderVisible = useAppSelector(selectLoaderStatus);

  if (!isLoaderVisible) return null;

  return (
    <div className="loaderModal">
      <div className="lds-spinner">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

export default Loader;
