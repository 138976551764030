import React, { FC } from "react";
import "./styles.scss";
import { IReplay } from "types";
import Images from "assets/images/index";

type TopReplayItemPropsType = {
  replay: IReplay;
};

const TopReplayItem: FC<TopReplayItemPropsType> = ({ replay }) => {
  return (
    <div className="replay__container">
      <video className="replay__content">
        <track kind="captions" />
        <source
          src={replay.media[0].awsLink}
          type={replay.media[0].mediaType}
        />
      </video>
      <div className="replay__toolsPanel">
        <div className="replay__toolsPanel__left">
          <img src={Images.Rectangle} alt="Rectangle" />
          <span className="replay__toolsPanel__left__username">
            {replay.author.username}
          </span>
        </div>
        <div className="replay__toolsPanel__right">
          <div className="infoReplayWrapper infoReplayWrapper margin">
            <img className="likeImage" src={Images.Like} alt="Like" />
            <p className="numberText">{replay.likes}</p>
          </div>
          <div className="infoReplayWrapper">
            <img src={Images.Comments} alt="Comment" className="commentImage" />
            <p className="numberText">{replay.comments}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopReplayItem;
