import React, { FC } from "react";
import "./index.scss";
import { LeaderBoard } from "./containers";
import SideBar from "./layouts/sideBar";
import { Loader } from "./components";

const App: FC = () => {
  return (
    <div className={"wrapper"}>
      <Loader />
      <SideBar />
      <main>
        <LeaderBoard />
      </main>
    </div>
  );
};

export default App;
