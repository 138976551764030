import { configureStore } from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";
import { Envs, getEnv } from "config";

import { reducers } from "./features";

const logger = createLogger({});

export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getEnv() === Envs.production
      ? getDefaultMiddleware()
      : getDefaultMiddleware().concat(logger),
  devTools: process.env.NODE_ENV !== "production",
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
