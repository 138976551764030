import { api } from "./index";
import { ILeaderBoard, IReqReplays } from "types";
import { AxiosResponse } from "axios";

export const getReplays = async (): Promise<AxiosResponse<IReqReplays>> => {
  return api.get<IReqReplays>("/api/posts/replays/");
};

export const getLeaderBoard = (): Promise<AxiosResponse<ILeaderBoard[]>> => {
  return api.get<ILeaderBoard[]>("/api/leaderboard/");
};
