import React, { FC } from "react";
import "./styles.scss";
import Images from "../../assets/images";

const SideBar: FC = () => {
  return (
    <div className="sidebar">
      <div className="sidebar__top">
        <img src={Images.Logo} alt="logo" className="sidebar__logo" />
      </div>
      <span className="sidebar__leaderBoard">LeaderBoard</span>
      <button className="sidebar__themeColorButton">Light | Dark</button>
    </div>
  );
};

export default SideBar;
