import React, { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectLeaderboard } from "store/features/leaderboard";
import { LeaderItem, TopReplayItem } from "components";
import "./styles.scss";
import {
  fetchLeaderBoardData,
  fetchReplaysData,
} from "store/features/leaderboard/leaderBoardSlice";

const LeaderBoardPage: FC = () => {
  const dispatch = useAppDispatch();
  const { leaderboard, replays } = useAppSelector(selectLeaderboard);

  useEffect(() => {
    dispatch(fetchLeaderBoardData());
    dispatch(fetchReplaysData());
  }, []);

  return (
    <div className="leaderBoardContent">
      <section>
        <h3 className="usersInfo__title">Top Replays</h3>
        <div className="replays__container">
          {replays?.map((replay) => (
            <TopReplayItem key={replay.id} replay={replay} />
          ))}
        </div>
      </section>
      <section className="usersInfo">
        <h3 className="usersInfo__title">LeaderBoard</h3>
        <div className="usersInfo__sortBlock">Sort Bar</div>
        <div className="gridTable">
          <div className="gridTable__headerItem headerItemLeftBorder">Rank</div>
          <div className="gridTable__headerItem">Change</div>
          <div className="gridTable__headerItem" />
          <div className="gridTable__headerItem">Name</div>
          <div className="gridTable__headerItem">Handle</div>
          <div className="gridTable__headerItem usersInfo__hiddenMobile" />
          <div className="gridTable__headerItem usersInfo__hiddenMobile">
            Team
          </div>
          <div className="gridTable__headerItem">Sport</div>
          <div className="gridTable__headerItem headerItemRightBorder">
            Points
          </div>
          {leaderboard?.map((item, index) => (
            <LeaderItem
              key={item.id}
              rank={index + 1}
              change={1}
              team="University California"
              sport="Soccer"
              {...item}
            />
          ))}
        </div>
      </section>
    </div>
  );
};

export default LeaderBoardPage;
