import Avatar from "./avatar.png";
import Logo from "./logo.png";
import RectangleWhite from "./rectangleWhite.png";
import TriangleDown from "./triangleDown.png";
import TriangleUp from "./triangleUp.png";
import Replay from "./replay.png";
import Comments from "./comments.png";
import Rectangle from "./rectangle.png";
import Like from "./like.png";

const images = {
  Avatar,
  Logo,
  RectangleWhite,
  Replay,
  TriangleDown,
  TriangleUp,
  Comments,
  Rectangle,
  Like,
};

export default images;
