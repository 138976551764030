const config = {};

export enum Envs {
  development = "development",
  staging = "staging",
  production = "production",
}

export const getEnv = (): Envs => {
  return process.env.NODE_ENV as Envs;
};

const envConfig = {
  [Envs.development]: {
    API_URL: "https://players-only-backend.herokuapp.com",
  },
  [Envs.staging]: {
    API_URL: "https://players-only-backend.herokuapp.com",
  },
  [Envs.production]: {
    API_URL: "https://players-only-backend.herokuapp.com",
  },
};

const configuration = {
  ...config,
  ...envConfig[getEnv()],
};

export default configuration;
