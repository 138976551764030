import React, { FC } from "react";
import "./styles.scss";
import Images from "../../assets/images";
import { ILeaderBoard } from "../../types";

interface LeaderBoardItemType extends ILeaderBoard {
  rank: number;
  change: number;
  team: string;
  sport: string;
}

const LeaderBoardItem: FC<LeaderBoardItemType> = (info) => {
  return (
    <>
      <div className="leaderItem leaderItem__rank">{info.rank}</div>
      <div className="leaderItem leaderItem__changePosition">
        <img
          src={info.change >= 0 ? Images.TriangleUp : Images.TriangleDown}
          alt="triangle"
          className="leaderItem__triangle"
        />
        <span className="leaderItem__numberPosition">
          {info.change >= 0 ? `+${info.change}` : info.change}
        </span>
      </div>
      <div className="leaderItem">
        <img
          src={info.avatar || Images.Avatar}
          alt="avatar"
          className="leaderItem__avatar"
        />
      </div>
      <div className="leaderItem">{`${info.firstName} ${info.lastName}`}</div>
      <div className="leaderItem">{info.username}</div>
      <div className="leaderItem leaderItem__hiddenMobile" />
      <div className="leaderItem leaderItem__hiddenMobile">{info.team}</div>
      <div className="leaderItem">{info.sport}</div>
      <div className="leaderItem leaderItem__points">{info.goatPoints}</div>
    </>
  );
};

export default LeaderBoardItem;
