import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ILeaderBoard, LeaderboardState, IReplay, ReplaysState } from "types";
import { getLeaderBoard, getReplays } from "../../../services/api/requests";
import { changeLoaderStatus } from "../common/loader/loaderSlice";

const initialState: LeaderboardState = {
  leaderboard: undefined,
  replays: undefined,
};

export const fetchReplaysData = createAsyncThunk<IReplay[] | undefined>(
  "leaderBoard/fetchReplaysData",
  async (_, thunkApi) => {
    try {
      const replaysData = await getReplays();
      return replaysData.data.posts;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const fetchLeaderBoardData = createAsyncThunk<
  ILeaderBoard[] | undefined
>("leaderBoard/fetchLeaderBoardData", async (_, thunkApi) => {
  thunkApi.dispatch(changeLoaderStatus(true));
  try {
    const leaderBoardRes = await getLeaderBoard();
    return leaderBoardRes.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  } finally {
    thunkApi.dispatch(changeLoaderStatus(false));
  }
});

const leaderBoardSlice = createSlice({
  name: "leaderBoard",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchLeaderBoardData.fulfilled.type]: (
      state: LeaderboardState,
      { payload }: PayloadAction<ILeaderBoard[]>
    ) => {
      state.leaderboard = payload;
    },
    [fetchReplaysData.fulfilled.type]: (
      state: ReplaysState,
      { payload }: PayloadAction<IReplay[]>
    ) => {
      state.replays = payload;
    },
  },
});

export default leaderBoardSlice.reducer;
