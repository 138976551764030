import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CommonStateType } from "../../../../types";

const initialState: CommonStateType = {
  isLoaderVisible: false,
};

const loaderSlice = createSlice({
  name: "loader",
  initialState,
  reducers: {
    changeLoaderStatus: (
      state: CommonStateType,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isLoaderVisible = payload;
    },
  },
});

export const { changeLoaderStatus } = loaderSlice.actions;
export default loaderSlice.reducer;
