import axios from "axios";
import config from "config";

export const api = axios.create({
  baseURL: config.API_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "PlayersOnly-Version": "2021-08-26",
  },
});
